import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeCustomPantsSlideState } from '../../store/slices/customePantsSlide'

const CustomWaistString = ({ changeWaistString, selectedValue }) => {
  const [selectedStringPosition, setSelectedStringPosition] = useState(
    selectedValue.selectedWaistString
  )
  const dispatch = useDispatch()

  const changeWaistStringHandler = () => {
    changeWaistString(selectedStringPosition)
    dispatch(changeCustomPantsSlideState())
  }

  return (
    <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto flex flex-col items-center">
      <div className="md:w-4/6 lg:w-full grid grid-cols-2 gap-5 mb-5">
        <div className="text-center mb-5">
          <img
            className="mb-5"
            src="/images/pants_items/waiststring_inside.jpg"
            alt="ドローストリング外側"
          />
          <input
            type="radio"
            name="waiststring"
            id="outside"
            value="外側"
            onChange={(e) => setSelectedStringPosition(e.target.value)}
            checked={selectedStringPosition === '外側'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedStringPosition === '外側' && 'bg-black text-white'
            }`}
            htmlFor="outside"
          >
            外側
          </label>
        </div>

        <div className="text-center mb-5">
          <img
            className="mb-5"
            src="/images/pants_items/waiststring_inside.jpg"
            alt="ドローストリング内側"
          />
          <input
            type="radio"
            name="waiststring"
            id="inside"
            value="内側"
            onChange={(e) => setSelectedStringPosition(e.target.value)}
            checked={selectedStringPosition === '内側'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedStringPosition === '内側' && 'bg-black text-white'
            }`}
            htmlFor="inside"
          >
            内側
          </label>
        </div>
      </div>

      <div className="mb-5 text-sm">
        折り返しの裾幅はストレートになりますので、若干広くなります。
      </div>

      <div className="w-full text-center">
        <button
          className="btn btn-primary btn-sm w-72"
          onClick={changeWaistStringHandler}
        >
          決定する
        </button>
      </div>
    </div>
  )
}

export default CustomWaistString
