import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useGetPantsSizeSpecQuery } from '../../store/apis/pantsSizeSpecApi'
import InseamAdjustment from './InseamAdjustment '
import { changeCustomPantsSlideState } from '../../store/slices/customePantsSlide'

const CustomePantsInseam = ({ changeInseam, selectedValue, type }) => {
  const [selectedSizeInseam, setSelectedSizeInseam] = useState(0)
  const [changedSizeInseam, setChangedSizeInseam] = useState(0)
  const [calcSize, setCalcSize] = useState(0)
  const dispatch = useDispatch()
  const { data: sizes } = useGetPantsSizeSpecQuery()

  useEffect(() => {
    if (sizes) {
      const selectedSize = sizes.find((size) => {
        return size.name === selectedValue.selectedSize
      })
      setSelectedSizeInseam(selectedSize.size[0].spec.inseam)
      setChangedSizeInseam(selectedSize.size[0].spec.inseam)
    }
  }, [sizes, changeInseam, selectedValue.selectedSize])

  useEffect(() => {
    if (changedSizeInseam - selectedSizeInseam > 0) {
      setCalcSize(changedSizeInseam - selectedSizeInseam)
    } else {
      setCalcSize(changedSizeInseam - selectedSizeInseam)
    }
  }, [changedSizeInseam, selectedSizeInseam])

  const handleClick = (value) => {
    if (value === 'short') {
      setChangedSizeInseam((prev) => prev - 1)
    } else {
      setChangedSizeInseam((prev) => prev + 1)
    }
  }

  const changeLengthHandler = () => {
    changeInseam(calcSize)
    dispatch(changeCustomPantsSlideState())
  }

  return (
    <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto">
      <div className="flex flex-col justify-center items-center">
        <div className="w-[90%] ml-16">
          <InseamAdjustment
            changedSizeInseam={changedSizeInseam}
            calcSize={calcSize}
            selectedValue={selectedValue}
            type={type}
          />
        </div>

        <div className="text-center text-2xl mb-12">
          <span className="text-sm mr-2">股下丈調整サイズ：</span>
          {calcSize === 0 ? `±0` : calcSize > 0 ? `+${calcSize}` : calcSize}
        </div>

        <div className="flex justify-center gap-7 mb-10">
          <button
            className="btn btn-sm btn-outline rounded-full w-32"
            onClick={() => handleClick('short')}
            disabled={calcSize <= -10}
          >
            短くする
          </button>
          <button
            className="btn btn-sm btn-outline rounded-full w-32"
            onClick={() => handleClick('long')}
            disabled={calcSize >= 10}
          >
            長くする
          </button>
        </div>
        <button
          className="btn btn-primary btn-sm w-72"
          onClick={changeLengthHandler}
        >
          決定する
        </button>
      </div>
    </div>
  )
}

export default CustomePantsInseam
