import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeCustomPantsSlideState } from '../../store/slices/customePantsSlide'

const CustomAnkle = ({ changeAnkle, selectedValue }) => {
  const [selectedAnkleType, setSelectedAnkleType] = useState(
    selectedValue.selectedAnkle
  )
  const dispatch = useDispatch()

  const changeAnkleHandler = () => {
    changeAnkle(selectedAnkleType)
    dispatch(changeCustomPantsSlideState())
  }

  return (
    <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto flex flex-col items-center">
      <div className="md:w-4/6 lg:w-full grid grid-cols-2 gap-5 mb-5 lg:grid-cols-3">
        <div className="text-center mb-5">
          <img
            className="mb-5"
            src="/images/pants_items/ankle_rib.jpg"
            alt="裾リブ"
          />
          <input
            type="radio"
            name="ankle"
            id="rib"
            value="リブ"
            onChange={(e) => setSelectedAnkleType(e.target.value)}
            checked={selectedAnkleType === 'リブ'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedAnkleType === 'リブ' && 'bg-black text-white'
            }`}
            htmlFor="rib"
          >
            リブ
          </label>
        </div>

        <div className="text-center mb-5">
          <img
            className="mb-5"
            src="/images/pants_items/ankle_rib.jpg"
            alt="裾絞り"
          />
          <input
            type="radio"
            name="ankle"
            id="squeeze"
            value="絞り"
            onChange={(e) => setSelectedAnkleType(e.target.value)}
            checked={selectedAnkleType === '絞り'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedAnkleType === '絞り' && 'bg-black text-white'
            }`}
            htmlFor="squeeze"
          >
            絞り
          </label>
        </div>

        <div className="text-center mb-">
          <img
            className="mb-5"
            src="/images/pants_items/ankle_rib.jpg"
            alt="裾折り返し"
          />
          <input
            type="radio"
            name="ankle"
            id="fold"
            value="折り返し"
            onChange={(e) => setSelectedAnkleType(e.target.value)}
            checked={selectedAnkleType === '折り返し'}
            className="hidden"
          />
          <label
            className={`btn btn-sm btn-outline rounded-full w-32 border-gray-400 ${
              selectedAnkleType === '折り返し' && 'bg-black text-white'
            }`}
            htmlFor="fold"
          >
            折り返し
          </label>
        </div>
      </div>

      <div className="mb-5 text-sm">
        折り返しの裾幅はストレートになりますので、若干広くなります。
      </div>

      <div className="w-full text-center">
        <button
          className="btn btn-primary btn-sm w-72"
          onClick={changeAnkleHandler}
        >
          決定する
        </button>
      </div>
    </div>
  )
}

export default CustomAnkle
