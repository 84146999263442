import { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import gsap from 'gsap'
import CustomPantsSize from '../components/pants/CustomPantsSize'
import CustomPantsStyle from '../components/pants/CustomPantsStyle'
import CustomPantsInseam from '../components/pants/CustomePantsInseam'
import CustomAnkle from '../components/pants/CustomAnkle'
import CustomWaistString from '../components/pants/CustomWaistString'
import CustomBackPocket from '../components/pants/CustomBackPocket'
import { changeCustomPantsSlideState } from '../store/slices/customePantsSlide'

const CustomPantsSlide = ({
  changeSize,
  changeStyle,
  changeInseam,
  changeAnkle,
  changeWaistString,
  changeBackPocket,
  selectedValue,
  type,
}) => {
  const { isCustomPantsSlideOpen, customPantsItem } = useSelector(
    (state) => state.customPantsSlide
  )
  const comp = useRef()
  const ctx = useRef()
  const tl = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    ctx.current = gsap.context(() => {
      tl.current = gsap.timeline({ pause: true })
      tl.current
        .to('.background-dark', { opacity: 0.3, duration: 0.1 })
        .to('.slide-menu', {
          xPercent: -100,
          duration: 0.3,
          ease: 'power2.inOut',
        })
    }, comp)
  }, [ctx])

  useEffect(() => {
    ctx.current.add(() => {
      isCustomPantsSlideOpen ? tl.current.play() : tl.current.reverse()
    })
    window.scrollTo(0, 0)
    isCustomPantsSlideOpen
      ? (document.body.style.position = 'fixed')
      : (document.body.style.position = 'static')
  }, [isCustomPantsSlideOpen])

  const checkingCustomPantsItem = () => {
    switch (customPantsItem) {
      case 'size':
        return (
          <CustomPantsSize
            changeSize={changeSize}
            selectedValue={selectedValue}
            type={type}
          />
        )
      case 'style':
        return (
          <CustomPantsStyle
            changeStyle={changeStyle}
            selectedValue={selectedValue}
            type={type}
          />
        )
      case 'inseam':
        return (
          <CustomPantsInseam
            changeInseam={changeInseam}
            selectedValue={selectedValue}
            type={type}
          />
        )
      case 'ankle':
        return (
          <CustomAnkle
            changeAnkle={changeAnkle}
            selectedValue={selectedValue}
            type={type}
          />
        )
      case 'waiststring':
        return (
          <CustomWaistString
            changeWaistString={changeWaistString}
            selectedValue={selectedValue}
            type={type}
          />
        )
      case 'backpocket':
        return (
          <CustomBackPocket
            changeBackPocket={changeBackPocket}
            selectedValue={selectedValue}
            type={type}
          />
        )
      default:
        return ''
    }
  }

  return (
    <div
      ref={comp}
      className={`overflow-hidden fixed top-14 left-0 z-20 w-full calc-height-screen ${
        !isCustomPantsSlideOpen && 'pointer-events-none'
      }`}
    >
      <div
        className="absolute top-0 left-0 background-dark bg-black w-full h-full opacity-0"
        onClick={() => dispatch(changeCustomPantsSlideState())}
      ></div>
      <div
        data-theme="lofi"
        className="slide-menu w-full bg-white lg:w-1/2 h-full flex flex-col items-center p-5 pt-20 lg:p-14 lg:pt-20 absolute top-0 -right-full lg:-right-1/2"
      >
        {checkingCustomPantsItem()}
        <div
          className="absolute inset-5 cursor-pointe w-10 h-10 flex items-center justify-center rounded-full cursor-pointer"
          onClick={() => dispatch(changeCustomPantsSlideState())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default CustomPantsSlide
