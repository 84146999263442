export const canEmbroidery = (name) => {
  let judge = true

  switch (name) {
    case 'Stretch T-shirt':
      judge = false
      break
    case 'Light Weight T-shirt':
      judge = false
      break
    case 'Pile T-shirt':
      judge = false
      break
    case 'Silky T-shirt':
      judge = false
      break
    case 'Waffle Long Sleeve T-shirt':
      judge = false
      break
    default:
      judge = true
  }

  return judge
}
