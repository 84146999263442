import { useState, useEffect, useRef } from 'react'

const InseamAdjustment = ({ changedSizeInseam, calcSize }) => {
  let length = changedSizeInseam
  const num = 3

  const [circle, setCircle] = useState(305.2 - num)
  const [horizon, setHorizon] = useState(304.8 - num)
  const [vertical, setVertical] = useState(85.6 - num)
  const [legsUpOut, setLegsUpOut] = useState(289.3 - num)
  const [legsLengthOut, setLegsLengthOut] = useState(107.8 - num)
  const [legsLengthIn, setLegsLengthIn] = useState(107.8 - num)
  const [rib, setRib] = useState(305.4 - num)
  const [stetch, setStetch] = useState(291.9 - num)

  function usePrevious(value) {
    const ref = useRef(null)
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const prevLength = usePrevious(length)

  useEffect(() => {
    if (prevLength > changedSizeInseam) {
      setCircle((prev) => prev - num)
      setHorizon((prev) => prev - num)
      setVertical((prev) => prev - num)
      setLegsUpOut((prev) => prev - num)
      setLegsLengthOut((prev) => prev - num)
      setLegsLengthIn((prev) => prev - num)
      setRib((prev) => prev - num)
      setStetch((prev) => prev - num)
    } else if (prevLength < changedSizeInseam) {
      setCircle((prev) => prev + num)
      setHorizon((prev) => prev + num)
      setVertical((prev) => prev + num)
      setLegsUpOut((prev) => prev + num)
      setLegsLengthOut((prev) => prev + num)
      setLegsLengthIn((prev) => prev + num)
      setRib((prev) => prev + num)
      setStetch((prev) => prev + num)
    }
  }, [changedSizeInseam, calcSize])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="\u30EC\u30A4\u30E4\u30FC_1"
      x={0}
      y={0}
      style={{
        enableBackground: 'new 0 0 470 350',
      }}
      viewBox="0 0 470 350"
    >
      <style>
        {
          '.st0{fill:none;stroke:#aaa;stroke-miterlimit:10}.st3{fill:#aaa}.st4,.st5,.st7{fill:none;stroke:#000;stroke-miterlimit:10}.st5,.st7{stroke-width:.5}.st7{fill:#fff}'
        }
      </style>
      <path d="M70.9 105.8h125.4M92.2 106.2v88" className="st0" />
      <text
        style={{
          fontSize: 13,
          fontFamily: '&quot',
        }}
        transform="translate(84.51 210.804)"
      >
        {changedSizeInseam}
      </text>
      <circle cx={92.1} cy={106.2} r={2.2} className="st3" />

      {/* 寸法 */}
      <circle cx={92.1} cy={circle} r={2.2} className="st3" />
      <path
        d={`M70.9 ${horizon}h58.7M92.2 219.6v${vertical}`}
        className="st0"
      />
      {/* リブ */}
      <path
        d={`M177.2 ${rib}h-36.7l-.9-16.1h38.5zM222.8 ${rib}h36.7l.9-16.1h-38.5z`}
        className="st4"
      />
      {/* リブステッチ */}
      <path d={`M139.6 ${stetch}h38.5M221.9 ${stetch}h38.5`} className="st5" />
      {/* 脚 */}
      <path
        d={`M139.6 ${legsUpOut}c-6.1-27-13.1-56.8-16-${legsLengthOut}M191 181.5c-4.6 44.8-9.8 75.2-12.7 ${legsLengthIn}`}
        className="st4"
      />
      <path
        d={`M209 181.5c4.6 44.8 9.8 75.2 12.7 ${legsLengthIn}M260.4 ${legsUpOut}c6.1-27 13.1-56.8 16-${legsLengthOut}`}
        className="st4"
      />

      <path
        d="M265.8 30.2s-48.5.9-65.8.9-65.8-.9-65.8-.9l.9-17s48.1.9 64.9.9 64.9-.9 64.9-.9l.9 17z"
        style={{
          fill: '#fff',
          stroke: '#000',
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M265.2 18.7s-47.8.9-65.2.9-64.9-.9-64.9-.9M265.5 24.7s-48.1.9-65.5.9-65.5-.9-65.5-.9"
        className="st7"
      />
      <path
        d="M123.6 181.5c-4.2-51-2-86.9 1.4-113.3 2.7-20.9 9.2-38 9.2-38M200 106.2c-1.4 3-5 37.1-9 75.3"
        className="st4"
      />
      <path d="M140.8 30.2s-7.8 35-17.1 48.3" className="st5" />
      <path
        d="M144.5 30.2s-8.8 42.7-21.2 51.9M255.5 30.2s8.8 42.7 21.2 51.9M200 106.2c1.4 3 5 37.1 9 75.3"
        className="st4"
      />
      <path d="M259.2 30.2s7.8 35 17.1 48.3" className="st5" />
      <path d="M200 106.2V31.1" className="st4" />
      <path d="M207.2 31.1v47.5s0 8.1-7.2 8.1" className="st5" />
      <path
        d="M276.4 181.5c4.2-51 2-86.9-1.4-113.3-2.7-20.9-9.2-38-9.2-38"
        className="st4"
      />
    </svg>
  )
}
export default InseamAdjustment
