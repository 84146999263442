import { rootApi } from './rootApi'
import { PANTS_URL } from '../../constants'

export const pantsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPants: builder.query({
      query: () => ({
        url: PANTS_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    getPantsDetails: builder.query({
      query: (pantsId) => ({
        url: `${PANTS_URL}/${pantsId}`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
})

export const { useGetPantsQuery, useGetPantsDetailsQuery } = pantsApi
