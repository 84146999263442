import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isCustomPantsSlideOpen: false,
  customPantsItem: '',
}

export const customPantsSlideSlice = createSlice({
  name: 'customPantsSlide',
  initialState,
  reducers: {
    changeCustomPantsSlideState: (state, action) => {
      state.isCustomPantsSlideOpen = !state.isCustomPantsSlideOpen
      state.customPantsItem = action.payload
    },
  },
})

export const { changeCustomPantsSlideState } = customPantsSlideSlice.actions

export default customPantsSlideSlice.reducer
