import { useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import gsap from 'gsap'
import { toggleMenuState } from '../store/slices/menuSlice'
import { logout } from '../store/slices/authSlice'
import { useLogoutMutation } from '../store/apis/usersApi'

const Menu = () => {
  const comp = useRef()
  const scrollMenu = useRef()
  const ctx = useRef()
  const tl = useRef()
  const { isMenuOpen } = useSelector((state) => state.menu)
  const { userInfo } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [logoutApiCall] = useLogoutMutation()

  useEffect(() => {
    ctx.current = gsap.context(() => {
      tl.current = gsap.timeline({ pause: true })
      tl.current
        .to('.background-dark', { opacity: 0.3, duration: 0.1 })
        .to('.slide-menu', {
          xPercent: 100,
          duration: 0.3,
          ease: 'power2.inOut',
        })
        .fromTo(
          '.menu-contents',
          { xPercent: -100, opacity: 0 },
          { xPercent: 0, opacity: 1, stagger: 0.1 }
        )
    }, comp)
  }, [ctx])

  useEffect(() => {
    ctx.current.add(() => {
      isMenuOpen ? tl.current.play() : tl.current.reverse()
    })
    isMenuOpen
      ? (document.body.style.position = 'fixed')
      : (document.body.style.position = 'static')
  }, [isMenuOpen])

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap()
      dispatch(logout())
      dispatch(toggleMenuState())
      navigate('/login')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      ref={comp}
      className={`absolute top-14 left-0 z-20 w-full calc-height-screen ${
        !isMenuOpen && 'pointer-events-none'
      }`}
    >
      <div
        className="absolute top-0 left-0 background-dark bg-black w-full h-full opacity-0"
        onClick={() => dispatch(toggleMenuState())}
      ></div>
      <div className="slide-menu w-full lg:w-[40%] h-full bg-white py-10 absolute top-0 -left-full lg:-left-[40%]">
        <div
          ref={scrollMenu}
          className="flex flex-col justify-between items-center h-svh w-full lg:h-full overflow-y-scroll"
        >
          <ul className="text-4xl/loose font-bold italic text-center w-full px-5 mb-10">
            <Link
              to="/tailor-tshirts"
              onClick={() => dispatch(toggleMenuState())}
            >
              <li className="menu-contents py-8 border-b hover:bg-gray-100">
                Tailor T-shirts
                <p className="text-xl -mt-3">テイラー Tシャツ</p>
                <p className="text-sm/6 text-gray-500 font-light mt-3">
                  採寸データを基にご要望を反映しながら
                  <br />
                  完璧なTシャツを目指すプレミアムなサービス
                </p>
              </li>
            </Link>
            <Link
              to="/customize-tshirts"
              onClick={() => dispatch(toggleMenuState())}
            >
              <li className="menu-contents py-8 border-b hover:bg-gray-100">
                Customize T-shirts
                <span className="block text-xl -mt-3">
                  カスタマイズ Tシャツ
                </span>
                <p className="text-sm/6 text-gray-500 font-light mt-3">
                  XS-2XLの6種 + 幅の異なる3種の全18サイズ
                  <br />
                  ネックや着丈、リブやポケットの変更が可能
                </p>
              </li>
            </Link>
            {/* <Link to="/pants" onClick={() => dispatch(toggleMenuState())}>
              <li className="menu-contents py-8 border-b hover:bg-gray-100">
                Pants
                <span className="block text-xl -mt-3">パンツ</span>
                <p className="text-sm/6 text-gray-500 font-light mt-3">
                  全18サイズ + 股下丈を無料で調整
                  <br />
                  裾の仕様や後ポケットもカスタマイズ可能
                </p>
              </li>
            </Link> */}
          </ul>
          <div className="menu-contents w-full px-5">
            {userInfo && userInfo.isAdmin && (
              <div className="flex gap-5 w-full mb-3">
                <Link
                  to="/admin/userlist"
                  className="btn btn-secondary text-sm flex-1"
                  onClick={() => dispatch(toggleMenuState())}
                >
                  顧客一覧
                </Link>
                <Link
                  to="/admin/orderlist"
                  className="btn btn-primary text-sm flex-1"
                  onClick={() => dispatch(toggleMenuState())}
                >
                  注文一覧
                </Link>
              </div>
            )}
            {userInfo ? (
              <div className="flex gap-5 w-full">
                <Link
                  to="/profile"
                  className="btn btn-secondary text-sm flex-1"
                  onClick={() => dispatch(toggleMenuState())}
                >
                  マイページ
                </Link>
                <Link
                  to="/login"
                  className="btn btn-primary text-sm flex-1"
                  onClick={logoutHandler}
                >
                  ログアウト
                </Link>
              </div>
            ) : (
              <Link
                to="/login"
                className="btn btn-primary w-full text-sm"
                onClick={() => dispatch(toggleMenuState())}
              >
                ログイン
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
