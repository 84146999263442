import { useState, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changeCustomPantsSlideState } from '../../store/slices/customePantsSlide'
import { useGetPantsDetailsQuery } from '../../store/apis/pantsApi'
import CustomPantsSlide from '../../components/CustomPantsSlide'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FiExternalLink } from 'react-icons/fi'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { pantsAddToCart } from '../../store/slices/cartSlice'

const PantsDetailScreen = () => {
  const { id } = useParams()
  const [selectedColor, setSelectedColor] = useState('')
  const [filteredImage, setFilteredImage] = useState([])
  const [selectedSize, setSelectedSize] = useState('M')
  const [selectedStyle, setSelectedStyle] = useState('Fit')
  const [changedInseam, setChangedInseam] = useState(0)
  const [changedInseamString, setChangedInseamString] = useState('±0 cm')
  const [selectedAnkle, setSelectedAnkle] = useState('リブ')
  const [selectedWaistString, setSelectedWaistString] = useState('外側')
  const [selectedBackPocket, setSelectedBackPocket] = useState('右側')
  const [optionPriceBackPocket, setOptionPriceBackPocket] = useState(0)

  const { data: item, isLoading, error } = useGetPantsDetailsQuery(id)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (item) {
      setSelectedColor(item.colors[0].colorName)
    }
  }, [item])

  useEffect(() => {
    if (selectedColor) {
      const filteredColor = item.colors.find(
        (i) => i.colorName === selectedColor
      )
      setFilteredImage(filteredColor.images)
    }
  }, [item, selectedColor])

  const customSlideHandler = (chnageItem) => {
    dispatch(changeCustomPantsSlideState(chnageItem))
  }

  // change status function
  const changeSize = (value) => {
    setSelectedSize(value)
  }
  const changeStyle = (value) => {
    setSelectedStyle(value)
  }
  const changeInseam = (value) => {
    setChangedInseam(value)
  }
  const changeAnkle = (value) => {
    setSelectedAnkle(value)
  }
  const changeWaistString = (value) => {
    setSelectedWaistString(value)
  }
  const changeBackPocket = (value, price) => {
    setSelectedBackPocket(value)
    setOptionPriceBackPocket((prev) => (value === '両側' ? prev + price : 0))
  }

  useEffect(() => {
    if (changedInseam === 0) {
      setChangedInseamString(`±${changedInseam} cm`)
    } else if (changedInseam > 0) {
      setChangedInseamString(`+${changedInseam} cm`)
    } else {
      setChangedInseamString(`${changedInseam} cm`)
    }
  }, [changedInseam])

  const addToCartHandler = (e) => {
    e.preventDefault()

    const colorImage = item.colors.find((x) => x.colorName === selectedColor)
      .images[0]

    dispatch(
      pantsAddToCart({
        _id: item._id,
        name: item.name,
        category: item.category,
        price: item.price + optionPriceBackPocket,
        image: colorImage,
        color: selectedColor,
        size: selectedSize,
        style: selectedStyle,
        inseam: changedInseamString,
        ankle: selectedAnkle,
        waiststring: selectedWaistString,
        backpocket: selectedBackPocket,
        type: item.type,
        qty: 1,
        cartId:
          new Date().getTime().toString(16) +
          Math.floor(1000 * Math.random()).toString(16),
      })
    )

    navigate('/cart')
  }

  return (
    <div className="grow flex justify-center">
      <div className="screen-container mt-5 w-full">
        {item && (
          <CustomPantsSlide
            changeSize={changeSize}
            changeStyle={changeStyle}
            changeInseam={changeInseam}
            changeAnkle={changeAnkle}
            changeWaistString={changeWaistString}
            changeBackPocket={changeBackPocket}
            selectedValue={{
              selectedSize,
              selectedStyle,
              selectedAnkle,
              selectedWaistString,
              selectedBackPocket,
            }}
            type={item.type}
          />
        )}

        <div className="text-sm breadcrumbs text-gray-500 mb-10">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/pants">Pants</Link>
            </li>
            <li>{item && item.name}</li>
          </ul>
        </div>

        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant={'alert-error'}>{error.data.message}</Message>
        ) : (
          <div className="w-full flex flex-col lg:flex-row lg:gap-10">
            <Swiper
              className="w-full lg:basis-1/2 xl:basis-2/3"
              slidesPerView={1}
              spaceBetween={20}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 1,
                },
                1280: {
                  slidesPerView: 2,
                },
              }}
            >
              {filteredImage.map((i, index) => (
                <SwiperSlide key={index}>
                  <LazyLoadImage
                    className="block w-full h-full object-cover"
                    src={i}
                    alt={index}
                    effect="blur"
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="basis-full  md:basis-1/3 lg:basis-1/2 xl:basis-1/3">
              <div className="hidden mb-10 lg:block">
                <h3 className="text-2xl font-semibold italic mb-2 flex items-center">
                  {item.newItem && (
                    <span className="text-sm bg-amber-600 rounded-full px-2 py-0.5 text-white mr-2">
                      New
                    </span>
                  )}
                  {item.name}
                </h3>

                <div className="flex items-center mb-3">
                  <p className="text-xl mr-5">
                    {item.price && item.price.toLocaleString()}
                    <span className="text-xs">円（税込）</span>
                  </p>
                  {/* レビュー */}
                </div>
                {item.features &&
                  item.features.map((feature, index) => (
                    <span
                      key={index}
                      className="text-xs border border-gray-300 py-0.5 px-2 rounded-full mr-1 inline-block mb-1"
                    >
                      {feature}
                    </span>
                  ))}

                {/* 商品説明モーダル */}
                <div className="mt-5">
                  <button
                    className="w-full border-y text-sm font-normal flex items-center pt-1.5 pb-1 px-2 hover:bg-gray-100"
                    onClick={() =>
                      document.getElementById('my_modal_3').showModal()
                    }
                  >
                    <FiExternalLink className="mr-2" /> 商品概要
                  </button>
                  <dialog id="my_modal_3" className="modal">
                    <div className="modal-box">
                      <form method="dialog">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                          ✕
                        </button>
                      </form>
                      <div className="overflow-x-auto p-5">
                        <h4 className="font-semibold mb-5">
                          {item.name}の商品概要
                        </h4>
                        <table className="table">
                          <tbody>
                            <tr className="border-b border-gray-300">
                              <th className="text-sm w-16">説明</th>
                              <td className="text-sm/6">{item.description}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                              <th className="text-sm w-16">素材</th>
                              <td className="text-sm/6">{item.fabric}</td>
                            </tr>
                            <tr className="border-b border-gray-300">
                              <th className="text-sm w-16">取扱</th>
                              <td className="text-sm/6">{item.care}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </dialog>
                </div>

                {/* フォーム */}
                <form onSubmit={addToCartHandler}>
                  {/* カラーセレクト */}
                  <div className="px-3 mt-7 border-b pb-2">
                    <div className="mb-2 text-sm">
                      <span>カラー :</span>
                      <span className="text-sm font-semibold ml-6">
                        {selectedColor}
                      </span>
                    </div>
                    <div className="flex">
                      {item.colors &&
                        item.colors.map((color) => (
                          <div key={color.colorName}>
                            <input
                              type="radio"
                              name="colors"
                              value={color.colorName}
                              onChange={(e) => setSelectedColor(e.target.value)}
                              id={color.colorName}
                              className="hidden colorBadge"
                              checked={color.colorName === selectedColor}
                            />
                            <label
                              htmlFor={color.colorName}
                              style={{ backgroundColor: `${color.colorBg}` }}
                              className="relative w-6 h-6 inline-block rounded-full border border-gray-500 mr-2 colorBadge-checked:border-black cursor-pointer"
                            >
                              <div className="colorChecked absolute inset-0 w-[22px] h-[22px] rounded-full border border-gray-300 hidden"></div>
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* カスタマイズ項目 */}
                  <ul className="text-sm">
                    <li className="py-3 border-b flex justify-between items-center px-3">
                      <div>
                        <span>サイズ :</span>
                        <span className="font-semibold ml-10">
                          {selectedSize}
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => customSlideHandler('size')}
                        className="btn btn-outline btn-xs text-xs font-light border-gray-300"
                      >
                        変更
                      </button>
                    </li>
                    <li className="py-3 border-b flex justify-between items-center px-3">
                      <div>
                        <span>スタイル :</span>
                        <span className="font-semibold ml-7">
                          {selectedStyle}
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => customSlideHandler('style')}
                        className="btn btn-outline btn-xs text-xs font-light border-gray-300"
                      >
                        変更
                      </button>
                    </li>
                    <li className="py-3 border-b flex justify-between items-center px-3">
                      <div>
                        <span>股下調整 :</span>
                        <span className="font-semibold ml-7">
                          {changedInseamString}
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => customSlideHandler('inseam')}
                        className="btn btn-outline btn-xs text-xs font-light border-gray-300"
                      >
                        変更
                      </button>
                    </li>
                    <li className="py-3 border-b flex justify-between items-center px-3">
                      <div>
                        <span>裾仕様 :</span>
                        <span className="font-semibold ml-10">
                          {selectedAnkle}
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => customSlideHandler('ankle')}
                        className="btn btn-outline btn-xs text-xs font-light border-gray-300"
                      >
                        変更
                      </button>
                    </li>
                    <li className="py-3 border-b flex justify-between items-center px-3">
                      <div>
                        <span>腰紐位置 :</span>
                        <span className="font-semibold ml-7">
                          {selectedWaistString}
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={() => customSlideHandler('waiststring')}
                        className="btn btn-outline btn-xs text-xs font-light border-gray-300"
                      >
                        変更
                      </button>
                    </li>
                    <li className="py-3 border-b flex justify-between items-center px-3">
                      <div>
                        <span>後ポケット :</span>
                        {selectedBackPocket === '右側' ? (
                          <span className="font-semibold ml-4">
                            {selectedBackPocket}
                          </span>
                        ) : selectedBackPocket === '左側' ? (
                          <span className="font-semibold ml-4">
                            {selectedBackPocket}
                          </span>
                        ) : (
                          <span className="font-semibold ml-4">
                            {selectedBackPocket} （+1,650円）
                          </span>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => customSlideHandler('backpocket')}
                        className="btn btn-outline btn-xs text-xs font-light border-gray-300"
                      >
                        オプション
                      </button>
                    </li>
                  </ul>

                  <button
                    type="submit"
                    className="btn btn-primary w-full mt-10"
                  >
                    カートに入れる
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PantsDetailScreen
