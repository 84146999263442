const SizeChartPants = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="\u30EC\u30A4\u30E4\u30FC_1"
    x={0}
    y={0}
    style={{
      enableBackground: 'new 0 0 450 400',
    }}
    viewBox="0 0 450 400"
  >
    <style>
      {
        '.st0{fill:none;stroke:#aaa;stroke-miterlimit:10}.st1{font-family:"NotoSansCJKjp-Regular-83pv-RKSJ-H"}.st2{font-size:13px}.st3{fill:#aaa}.st5,.st6,.st7{stroke:#000;stroke-miterlimit:10}.st5{fill:#fff;stroke-width:.5}.st6,.st7{fill:none}.st7{stroke-width:.5}'
      }
    </style>
    <path
      d="M153.1 19.8h49.5M242.1 19.8h52.5M151.6 6.8v27.3M294.6 6.8v27.3"
      className="st0"
    />
    <text className="st1 st2" transform="translate(214.87 24.89)">
      {size.waist}
    </text>
    <circle cx={151.6} cy={20.1} r={2.5} className="st3" />
    <circle cx={294.6} cy={20.1} r={2.5} className="st3" />
    <path
      d="M79.9 39H148M79.9 141.3h139.3M103.5 36.1v41.4M103.5 141.7V244M103.5 103.8v37.9"
      className="st0"
    />
    <text className="st1 st2" transform="translate(95.76 94.952)">
      {size.rise}
    </text>
    <text className="st1 st2" transform="translate(95.76 262.441)">
      {size.inseam}
    </text>
    <circle cx={103.4} cy={141.7} r={2.5} className="st3" />
    <path d="M79.9 362.5H145M103.5 272.2v90.7" className="st0" />
    <circle cx={103.4} cy={362.9} r={2.5} className="st3" />
    <circle cx={103.4} cy={38.6} r={2.5} className="st3" />
    <path d="M157.1 365.8v27M157.6 379.9h7.1" className="st0" />
    <text className="st1 st2" transform="translate(170.422 384.683)">
      {size.hem}
    </text>
    <circle cx={157.1} cy={379.5} r={2.5} className="st3" />
    <path d="M198.1 365.8v27M197.6 379.9h-7" className="st0" />
    <circle cx={198.1} cy={379.5} r={2.5} className="st3" />
    <path
      d="M296.4 57.3s-53.9 1-73.1 1-73.1-1-73.1-1l1-18.8s53.4 1 72.1 1 72.1-1 72.1-1l1 18.8z"
      style={{
        fill: '#fff',
        stroke: '#000',
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M295.7 44.5s-53.2 1-72.4 1-72.1-1-72.1-1M296 51.2s-53.5 1-72.8 1-72.8-1-72.8-1"
      className="st5"
    />
    <path
      d="M223.3 141.7c-3.5 7.2-18.1 177.5-24.1 203.5h-43.1c-6.8-30-15.6-102.4-17.8-148.6-2.7-56.8-2.2-67.7 1.6-97 3-23.3 10.2-42.3 10.2-42.3M198 363.1h-40.8l-1-17.9H199z"
      className="st6"
    />
    <path d="M157.5 57.3s-8.6 38.8-19 53.7" className="st7" />
    <path
      d="M161.6 57.3s-9.7 47.5-23.5 57.7M284.9 57.3s9.7 47.5 23.5 57.7"
      className="st6"
    />
    <path
      d="M223.3 141.7c3.5 7.2 18.1 177.5 24.1 203.5h43.1c6.8-30 15.6-102.4 17.8-148.6 2.7-56.8 2.2-67.7-1.6-97-3-23.3-10.2-42.3-10.2-42.3M248.6 363.1h40.8l1-17.9h-42.8z"
      className="st6"
    />
    <path d="M289.1 57.3s8.6 38.8 19 53.7" className="st7" />
    <path d="M223.3 141.7V58.3" className="st6" />
    <path
      d="M231.3 58.3V111s0 9-8 9M156.2 348.1H199M247.6 348.1h42.8"
      className="st7"
    />
    <path d="M137.7 126.4h119.4M292.7 126.4h16.7" className="st0" />
    <text className="st1 st2" transform="translate(266.302 131.016)">
      {size.hip}
    </text>
    <circle cx={137.1} cy={126.4} r={2.5} className="st3" />
    <circle cx={309.4} cy={126.4} r={2.5} className="st3" />
    <path d="M230.2 191.8h20.9M286.7 191.8h22.7" className="st0" />
    <text className="st1 st2" transform="translate(260.805 196.338)">
      {size.thigh}
    </text>
    <circle cx={229.8} cy={191.8} r={2.5} className="st3" />
    <circle cx={308.5} cy={191.8} r={2.5} className="st3" />
  </svg>
)

export default SizeChartPants
