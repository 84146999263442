import { rootApi } from './rootApi'
import { PANTS_SIZE_URL } from '../../constants'

export const pantsSizeSpecApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPantsSizeSpec: builder.query({
      query: () => ({
        url: PANTS_SIZE_URL,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
})

export const { useGetPantsSizeSpecQuery } = pantsSizeSpecApi
