import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeCustomPantsSlideState } from '../../store/slices/customePantsSlide'

const CustomBackPocket = ({ changeBackPocket, selectedValue }) => {
  const [selectedBackPocket, setSelectedBackPocket] = useState(
    selectedValue.selectedBackPocket
  )
  const dispatch = useDispatch()

  const changeBackPocketHandler = () => {
    changeBackPocket(
      selectedBackPocket,
      selectedBackPocket === '両側' ? 1650 : 0
    )
    dispatch(changeCustomPantsSlideState())
  }
  return (
    <div className="w-full h-full pb-28 lg:pb-10 overflow-y-auto">
      <div className="flex flex-col justify-center mb-10">
        <div className="grid grid-cols-2 gap-10 w-full mb-10">
          <div className="flex flex-col items-center">
            <img
              className="w-full lg:w-[75%] mb-2"
              src="/images/custom_items/pocket-none.png"
              alt="後ポケット右側"
            />
            <input
              type="radio"
              name="backpocket"
              id="rightside"
              value="右側"
              checked={selectedBackPocket === '右側'}
              className="hidden"
              onChange={(e) => setSelectedBackPocket(e.target.value)}
            />
            <label
              className={`text-xs xl:text-sm btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                selectedBackPocket === '右側' && 'bg-black text-white'
              }`}
              htmlFor="rightside"
            >
              右側
            </label>
          </div>

          <div className="flex flex-col items-center">
            <img
              className="w-full lg:w-[75%] mb-2"
              src="/images/custom_items/pocket-none.png"
              alt="後ポケット左側"
            />
            <input
              type="radio"
              name="backpocket"
              id="leftside"
              value="左側"
              checked={selectedBackPocket === '左側'}
              className="hidden"
              onChange={(e) => setSelectedBackPocket(e.target.value)}
            />
            <label
              className={`text-xs xl:text-sm btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                selectedBackPocket === '左側' && 'bg-black text-white'
              }`}
              htmlFor="leftside"
            >
              左側
            </label>
          </div>

          <div className="flex flex-col items-center">
            <img
              className="w-full lg:w-[75%] mb-2"
              src="/images/custom_items/pocket-none.png"
              alt="後ポケット両側"
            />
            <input
              type="radio"
              name="backpocket"
              id="bothside"
              value="両側"
              checked={selectedBackPocket === '両側'}
              className="hidden"
              onChange={(e) => setSelectedBackPocket(e.target.value)}
            />
            <label
              className={`text-xs xl:text-sm btn btn-sm btn-outline rounded-full border-gray-400 w-full xl:w-64 ${
                selectedBackPocket === '両側' && 'bg-black text-white'
              }`}
              htmlFor="bothside"
            >
              両側（1,650円）
            </label>
          </div>
        </div>
      </div>
      <div className="w-full text-center">
        <button
          className="btn btn-primary btn-sm w-72"
          onClick={changeBackPocketHandler}
        >
          決定する
        </button>
      </div>
    </div>
  )
}

export default CustomBackPocket
