import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IoMdClose } from 'react-icons/io'
import Message from '../components/Message'
import {
  addToCart,
  removeFromCart,
  pantsAddToCart,
} from '../store/slices/cartSlice'
import Meta from '../components/Meta'

const CartScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const addToCartHandler = async (product, qty) => {
    dispatch(addToCart({ ...product, qty }))
  }
  const pantsAddToCartHandler = async (product, qty) => {
    dispatch(pantsAddToCart({ ...product, qty }))
  }
  const removeFromCartHandler = async (cartId) => {
    dispatch(removeFromCart(cartId))
  }
  const checkoutHandler = () => {
    navigate('/login?redirect=/shipping')
  }

  return (
    <>
      <Meta title="ショッピングカート | m&s Fabric" />
      <div className="flex justify-center">
        <div className="screen-container mt-5 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Shopping Cart</li>
            </ul>
          </div>
          <h2 className="page-title">Shopping Cart</h2>
          <p className="page-description">ショッピングカート</p>
          {cartItems.length === 0 ? (
            <div className="mb-20">
              <Message variant={''}>カートに商品は入っていません</Message>
            </div>
          ) : (
            <div className="flex flex-col lg:flex-row w-full mb-10 lg:mb-20 gap-10">
              <div className="basis-3/4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
                {cartItems.map((item) => {
                  if (item.category === 'Tailor T-shirts' && item.isNew) {
                    return (
                      <div
                        className="flex gap-5 w-full border border-gray-300 p-3 rounded-md"
                        key={item.cartId}
                      >
                        <div className="basis-1/4">
                          <Link to="/tailor-tshirts/body">
                            <img src={item.image} alt={item.name} />
                          </Link>
                        </div>
                        <div className="basis-3/4">
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-xs text-white bg-black px-2 rounded-full inline-block">
                              {item.category}
                            </p>
                            <button type="button">
                              <IoMdClose
                                onClick={() =>
                                  removeFromCartHandler(item.cartId)
                                }
                              />
                            </button>
                          </div>
                          <h3 className="text-lg font-bold italic">
                            {item.name}
                          </h3>
                          <p className="mb-3">
                            <span className="text-xs">価格：</span>
                            {item.price.toLocaleString()}
                            <span className="text-xs">円（税込）</span>
                          </p>
                          <ul className="text-sm">
                            <li>カラー：{item.color}</li>
                            <li>サイズ：オーダーメイド</li>
                            <li>ネック：{item.neck}</li>
                            <li>ネックリブ：{item.neckLib}cm</li>
                          </ul>
                        </div>
                      </div>
                    )
                  } else if (
                    item.category === 'Tailor T-shirts' &&
                    !item.isNew
                  ) {
                    return (
                      <div
                        className="flex gap-5 w-full border border-gray-300 p-3 rounded-md"
                        key={item.cartId}
                      >
                        <div className="basis-1/4">
                          <Link to={`/tailor-tshirts/${item._id}`}>
                            <img src={item.image} alt={item.name} />
                          </Link>
                          <div className="mb-3">
                            <select
                              value={item.qty}
                              className="select select-sm select-bordered mt-5 w-full"
                              onChange={(e) =>
                                addToCartHandler(item, Number(e.target.value))
                              }
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                          <Link to={`/tailor-tshirts/${item._id}`}>
                            <button className="btn btn-xs w-full rounteded font-normal">
                              戻る
                            </button>
                          </Link>
                        </div>
                        <div className="basis-3/4">
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-xs text-white bg-black px-2 rounded-full inline-block">
                              {item.category} / Repeat
                            </p>
                            <button type="button">
                              <IoMdClose
                                onClick={() =>
                                  removeFromCartHandler(item.cartId)
                                }
                              />
                            </button>
                          </div>
                          <h3 className="text-lg font-bold italic">
                            {item.name}
                          </h3>
                          <p className="mb-3">
                            <span className="text-xs">価格：</span>
                            {item.price.toLocaleString()}
                            <span className="text-xs">円（税込）</span>
                          </p>
                          <ul className="text-sm leading-6">
                            <li>カラー：{item.color}</li>
                            <li>サイズ：{item.size}</li>
                            <li>タグ：{item.tag}</li>
                            <li>リブ：{item.lib}</li>
                            <li>
                              ポケット：
                              {item.pocket.pocketType !== '付けない' &&
                                item.pocket.pocketPosition}
                              {item.pocket.pocketType !== '付けない' && (
                                <span> / </span>
                              )}
                              {item.pocket.pocketType}
                            </li>
                            <li>
                              名入れ：
                              {item.embroidery.isEmbroidery !== '無し' &&
                                item.embroidery.embroideryChar}
                              {item.embroidery.isEmbroidery !== '無し' && (
                                <span> / </span>
                              )}
                              {item.embroidery.isEmbroidery !== '無し' &&
                                item.embroidery.embroideryColor}
                              {item.embroidery.isEmbroidery === '無し' &&
                                item.embroidery.isEmbroidery}
                            </li>
                            <li>
                              サイズ変更：
                              {item.fixSize === '' ? 'なし' : 'あり'}
                            </li>
                          </ul>
                        </div>
                      </div>
                    )
                  } else if (item.category === 'Customize T-shirts') {
                    return (
                      <div
                        className="flex gap-5 w-full border border-gray-300 p-3 rounded-md"
                        key={item.cartId}
                      >
                        <div className="basis-1/4">
                          <Link to={`/customize-tshirts/${item._id}`}>
                            <img src={item.image} alt={item.name} />
                          </Link>
                          <div className="mb-3">
                            <select
                              value={item.qty}
                              className="select select-sm select-bordered mt-5 w-full"
                              onChange={(e) =>
                                addToCartHandler(item, Number(e.target.value))
                              }
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                          <Link to={`/customize-tshirts/${item._id}`}>
                            <button className="btn btn-xs w-full rounteded font-normal">
                              戻る
                            </button>
                          </Link>
                        </div>
                        <div className="basis-3/4">
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-xs border border-gray-400 px-2 rounded-full inline-block">
                              {item.category}
                            </p>
                            <button type="button">
                              <IoMdClose
                                onClick={() =>
                                  removeFromCartHandler(item.cartId)
                                }
                              />
                            </button>
                          </div>
                          <h3 className="text-lg font-bold italic">
                            {item.name}
                          </h3>
                          <p className="mb-3">
                            <span className="text-xs">価格：</span>
                            {item.price.toLocaleString()}
                            <span className="text-xs">円（税込）</span>
                          </p>
                          <ul className="text-sm">
                            <li>カラー：{item.color}</li>
                            <li>サイズ：{item.size}</li>
                            <li>ネック：{item.neck}</li>
                            <li>スタイル：{item.style}</li>
                            <li>着丈：{item.length}</li>
                            {item.type === 'Long T-shirts' && (
                              <li>袖丈：{item.sleeveLength}</li>
                            )}
                            <li className="text-[13px]">リブ：{item.lib}</li>

                            <li className="text-[13px]">
                              ポケット：
                              {item.pocket.pocketType !== '付けない' &&
                                item.pocket.pocketPosition}
                              {item.pocket.pocketType !== '付けない' && (
                                <span> / </span>
                              )}
                              {item.pocket.pocketType}
                            </li>
                          </ul>
                        </div>
                      </div>
                    )
                  } else if (item.category === 'Pants') {
                    return (
                      <div
                        className="flex gap-5 w-full border border-gray-300 p-3 rounded-md"
                        key={item.cartId}
                      >
                        <div className="basis-1/4">
                          <Link to={`/pants/${item._id}`}>
                            <img src={item.image} alt={item.name} />
                          </Link>
                          <div className="mb-3">
                            <select
                              value={item.qty}
                              className="select select-sm select-bordered mt-5 w-full"
                              onChange={(e) =>
                                pantsAddToCartHandler(
                                  item,
                                  Number(e.target.value)
                                )
                              }
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                          <Link to={`/pants/${item._id}`}>
                            <button className="btn btn-xs w-full rounteded font-normal">
                              戻る
                            </button>
                          </Link>
                        </div>
                        <div className="basis-3/4">
                          <div className="flex items-center justify-between mb-2">
                            <p className="text-xs border border-gray-400 px-2 rounded-full inline-block">
                              {item.category}
                            </p>
                            <button type="button">
                              <IoMdClose
                                onClick={() =>
                                  removeFromCartHandler(item.cartId)
                                }
                              />
                            </button>
                          </div>
                          <h3 className="text-lg font-bold italic">
                            {item.name}
                          </h3>
                          <p className="mb-3">
                            <span className="text-xs">価格：</span>
                            {item.price.toLocaleString()}
                            <span className="text-xs">円（税込）</span>
                          </p>
                          <ul className="text-sm">
                            <li>カラー：{item.color}</li>
                            <li>サイズ：{item.size}</li>
                            <li>スタイル：{item.style}</li>
                            <li>股下調整：{item.inseam}</li>
                            <li>裾仕様：{item.ankle}</li>
                            <li>腰紐位置：{item.waiststring}</li>
                            <li>後ポケット：{item.backpocket}</li>
                          </ul>
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div className="mb-20">
                        <Message variant={''}>適正な商品ではありません</Message>
                      </div>
                    )
                  }
                })}
              </div>
              <div className="basis-1/4 bg-gray-100 p-5 flex flex-col justify-between">
                <div className="text-right">
                  <p className="text-sm">
                    カートに入っている商品：
                    <span className="text-2xl font-semibold">
                      {cartItems.reduce(
                        (acc, item) => acc + Number(item.qty),
                        0
                      )}
                    </span>
                    点
                  </p>
                  <p className="text-sm pb-5 border-b border-gray-400 mb-3">
                    小計：
                    <span className="text-2xl font-semibold">
                      {cartItems
                        .reduce(
                          (acc, item) => acc + Number(item.qty) * item.price,
                          0
                        )
                        .toLocaleString()}
                    </span>
                    円（税込）
                  </p>
                  <ul className="text-xs text-left mb-5">
                    <li>
                      送料は地域によって異なるため、配送先入力後に表示されます。
                    </li>
                  </ul>
                </div>

                <div>
                  <button
                    onClick={checkoutHandler}
                    className="btn btn-primary w-full"
                  >
                    お客様情報の入力に進む
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CartScreen
