import { Link } from 'react-router-dom'
import PantsIndexItems from '../../components/pants/PantsIndexItems'
import Meta from '../../components/Meta'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { useGetPantsQuery } from '../../store/apis/pantsApi'

const PantsScreen = () => {
  const { data: pants, isLoading, error } = useGetPantsQuery()

  return (
    <>
      <Meta title="カスタマイズTシャツ | m&s Fabric" />
      <div className="flex justify-center grow">
        <div className="screen-container mt-5 mb-14 w-full">
          <div className="text-sm breadcrumbs text-gray-500 mb-10">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Pants</li>
            </ul>
          </div>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant={'alert-error'}>{error.data.message}</Message>
          ) : (
            <>
              <h2 className="page-title mt-10">Pants</h2>
              <p className="page-description">
                股下丈を調整できるカスタマイズパンツ。オプションでテイラーもご用意。
              </p>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {pants.map((item) => (
                  <PantsIndexItems item={item} key={item._id} />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default PantsScreen
