import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import menuReducer from './slices/menuSlice'
import customSlideReducer from './slices/customSlideSlice'
import authReducer from './slices/authSlice'
import cartReducer from './slices/cartSlice'
import tailorSpecReducer from './slices/tailorSlice'
import fabricSampleReducer from './slices/fabricSampleSlice'
import customPantsSlideReducer from './slices/customePantsSlide'
import { rootApi } from './apis/rootApi'

export const store = configureStore({
  reducer: {
    [rootApi.reducerPath]: rootApi.reducer,
    auth: authReducer,
    cart: cartReducer,
    menu: menuReducer,
    customSlide: customSlideReducer,
    tailorSpec: tailorSpecReducer,
    fabricSample: fabricSampleReducer,
    customPantsSlide: customPantsSlideReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootApi.middleware),
  devTools: true,
})

setupListeners(store.dispatch)
